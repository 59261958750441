import { motion } from "framer-motion";
import styles from "../styles";
import { footerVariants } from "../utils/motion";
import { useState } from "react";

const Footer = () => {
  const [navbar, setNavbar ] = useState(true)
  return(

  
  <motion.footer
    variants={footerVariants}
    initial='hidden'
    whileInView='show'
    viewport={{ once : true, amount: 0.25 }}
    className={`${styles.paddings} py-8 relative `} 
    style={{ background: '#3d3d3d' }}
    id="order"
  >
    {/* <div className=" " /> */}

    <div className={`${styles.innerWidth} mx-auto flex flex-col gap-8 `}>
      {/* <div className="flex footer-button items-center justify-between flex-wrap gap-5 ">
        <h4 className="font-bold md:text-[64px] text-[44px] text-center text-white ">
         Get it Delivered
        </h4>
        <a href="https://wa.me/254741799330" target="_blank" className="flex items-center h-fit py-4 px-6 bg-[#F9EFE6] rounded-[32px] gap-[12px] " rel="noreferrer">
          <span className="regular text-[20px] ml-[4px] ">New Order</span>
          <img src="/assets/icons/orderbike.svg" alt="deliverFood" className="w-[56px] h-[56px] object-contain " />

        </a>
      </div> */}

      <div className="flex flex-col ">
        <div className="mb-[50px] h-[2px] bg-white opacity-10  " />

        <div className="flex items-center justify-between flex-wrap gap-8 ">
        <div
                className={` justify-self-center footer-box items-center pb-2 mt-1 md:block md:pb-0 md:mt-0 
                p-4 md:p-0 block
               `
              }
              >
        <ul className={`h-auto opacity-80 items-center justify-center 
                    p-4 md:p-0 block
                `}>
                  <li className="pb-2   text-[#fff] py-2 px-6 text-center border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                  

                    <a href="#about" onClick={() => setNavbar(!navbar)}>
                      THE BRAND
                    </a>
                  
                    {/* </div> */}
                  </li>
                  <li className=" text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#our-menu" onClick={() => setNavbar(!navbar)}>
                      OUR MENU                
                    </a>
                  
                  </li>
                  <li className=" text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#whats-new" onClick={() => setNavbar(!navbar)}>
                      WHAT'S NEW
                    </a>
                  
                  </li>
                  {/* <li className=" text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="mailto:papparotike@gmail.com" onClick={() => setNavbar(!navbar)}>
                      FRANCHISE
                    </a>
                  
                  </li> */}
                  {/* <li className=" text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#order" onClick={() => setNavbar(!navbar)}>
                      FIND US
                    </a>
                   
                  </li> */}
                  {/* <li className=" text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090] md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#order" onClick={() => setNavbar(!navbar)}>
                      ORDER
                    </a>
                    
                  </li> */}
                  <li className=" text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090] md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="https://www.instagram.com/papparotikenya/" target="_blank" onClick={() => setNavbar(!navbar)} rel="noreferrer">
                      BE SOCIAL
                    </a>
                  </li>
                  <li className=" text-[#fff] py-1 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090] md:hover:text-[#fff] md:hover:bg-transparent">
                   
                  </li>
                </ul>
                </div>
                <div className=" pb-4 footer-box justify-self-center items-center">
                  <h4 className="semi-bold text-white mb-6">OUR LOCATIONS</h4>
                  <h5 className=" text-white">The Cube Shopping Mall</h5>
                  <p className=" text-white">Copper Road, Kampala</p>
                  <a href="https://maps.app.goo.gl/KiK9uvRP9W1qhVhJA" target="_blank" className="flex justify-center footer-box  h-fit pb-2 w-[200px] rounded-[32px]" rel="noreferrer">
                    <div className=" border-white mt-4 border-[0.5px] flex justify-self-center flex-row gap-1 rounded-xl  p-2">
                    <img src="/assets/icons/map-pin.svg" alt="PappaRoti Kampala Directions" className="w-[24px] h-[24px] object-contain " />
                    <span className="regular text-white ml-[4px] mr-1 ">Location Pin</span>
                    </div>
                  </a>
                  <a href="tel:+256754444063" target="_blank" className="flex justify-center footer-box  h-fit py-2 w-[200px] rounded-[32px]" rel="noreferrer">
                    <div className=" border-white mt-4 border-[0.5px] flex justify-self-center flex-row gap-1 rounded-xl  p-2">
                    <img src="/assets/icons/call.png" alt="PappaRoti Uganda Contacts" className="w-[24px] h-[24px] object-contain " />
                    <span className="regular text-white ml-[4px] mr-1 ">Call 0754444063</span>
                    </div>
                  </a>
                 
                  {/* <p className=" text-white mt-2">+</p> */}
                </div>

        </div>
              <p className="font-normal py-4 text-center text-[14px] text-white opacity-50 ">Copyright ©️ Papparoti Uganda. All rights reserved </p>
      
      </div>
    </div>

  </motion.footer>
)};

export default Footer;

'use client';
import { motion } from "framer-motion";
import styles from "../styles";
import { staggerContainer } from '../utils/motion';

import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;


function SimpleMap(){
}
const defaultProps = {
  center: {
    lat: 0.338321,
    lng: 32.586852
  },
  zoom: 18
}
const Countries = () => (
  <section id="countries" className={` ${styles.paddings} relative z-10`}> 
    
      <motion.div variants={staggerContainer} initial='hidden' whileInView='show' 
        className={`${styles.innerWidth} pt-12 mx-auto flex  flex-col gap-8 `}
        viewport={{ once : true, amount: 0.25 }}
      >
        <div className="flex-1 flex lg:flex-row flex-col align-top ">
          <div className="flex-[0.5] sm:mb-2 mb-4">
            <h2 className=" justify-start brand-font text-[35px]">ONE BUN<br/> 70+ COUNTRIES </h2>
            <h4 className=" text-[20px] my-4 mt-12 ">
            The star of PappaRoti is the bun, it has a huge fan following that goes beyond celebrities and royalties. Haven’t you tried the famous PappaRoti bun yet? If you have, you would have every reason to franchise with us.
            </h4>

          </div>
          <div className="flex-[0.5] ">
        <div className=" max-h-[700px]">
          <img src="/assets/images/worldmap.png" className=" object-cover" alt="" />
        </div> 
          </div>
     
        </div>
        {/* <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={0.338321}
          lng={32.586852}
          text="My Marker"
        />
      </GoogleMapReact>
    </div> */}
              <a href="https://maps.app.goo.gl/KiK9uvRP9W1qhVhJA" target="_blank" rel="noreferrer" >

        <div className=" w-full ">
          <img src="/assets/images/papparotiug.png" className=" object-cover" alt="" />
        </div> 
        </a>

  
  
      </motion.div>
    </section>
);

export default Countries;
